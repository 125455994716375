header
  position: fixed
  top: 0px
  height: 100px
  z-index: 1
  min-width: 100%
  display: grid
  grid-template-columns: 1fr 5fr 1fr
  grid-auto-rows: 70px 30px

.title
  display: flex
  justify-content: center
  align-items: center
  text-transform: uppercase
  margin: $margin
  grid-column: 2
  grid-row: 1

h1
  font-family: $first-font-family
  margin: 0
  color: $first-color
  @media screen and (min-width: $small)
    font-size: 28px
  @media screen and (max-width: $small)
    font-size: 22px

.languages
  display: flex
  justify-content: center
  align-items: center
  grid-column: 3
  grid-row: 1

.language-mask
  overflow: hidden
  position: relative
  height: 24px
  width: 24px
  font-size: 18px

.languages-content
  position: absolute
  display: grid
  grid-template-rows: repeat(2, 1fr)
  transition: $scrolling-transition
  &:nth-child(1)
    grid-row: 1
  &:nth-child(2)
    grid-row: 2
  span
    display: flex
    justify-content: center
    align-items: center
    height: 16px
    width: 16px
    padding: 4px
    font-family: $second-font-family
    text-transform: uppercase
    color: $second-color

.categories
  grid-column: 2
  grid-row: 2
  display: flex
  justify-content: center

.category-mask
  overflow: hidden
  position: relative
  height: 100%
  @media screen and (min-width: $small)
    width: 50%
  @media screen and (max-width: $small)
    width: 75%

.categories-content
  position: absolute
  display: grid
  grid-template-columns: repeat(5, 100%)
  width: 100%
  text-align: center
  transition: $scrolling-transition

