.cursor
    position: absolute
    z-index: 9999
    width: 10px
    height: 10px
    opacity: 1
    border-radius: 50%
    background-color: $third-color
    transform: translate(-50%, -50%)
    mix-blend-mode: difference
    pointer-events: none
    transition: width 300ms, height 300ms

.cursor-hover
    width: 20px
    height: 20px

.cursor-active
    width: 30px
    height: 30px

.ring
    position: absolute
    z-index: 9999
    width: 30px
    height: 30px
    border-radius: 50%
    opacity: 0
    border: 2px solid $third-color
    mix-blend-mode: difference
    transition: transform 200ms ease-out, opacity 1s ease-out 1s
    pointer-events: none
