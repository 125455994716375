@keyframes text-scrolling
  0% 
    transform: translate(-50%, -100%)
  100% 
    transform: translate(0%, -100%)
  
@keyframes fade-in
  0% 
    opacity: 0
  100% 
    opacity: 1