.video
  left: 0px

.card-video
  margin: 0px 10px 
  display: flex
  align-items: center
  justify-content: center
  background-color: $card-background
  border-radius: $card-border-radius
  padding: 5px
  &:nth-child(2n)
    border-top: $card-border-dark
  &:nth-child(2n+1)
    border-top: $card-border-light
  @media screen and (min-width: $medium)
    width: 560px
    height: 350px
  @media screen and (max-width: $medium)
    width: 400px
    height: 250px
  iframe
    @media screen and (min-width: $medium)
      width: 500px
      height: 281px
    @media screen and (max-width: $medium)
      width: 356px
      height: 200px
