.bg-text-container
  position: absolute
  transform: skewY(-5deg)
  index: -999
  left: 10%
  top: -2%

.animate-text
  font-family: 'Playfair Display normal'
  text-transform: uppercase
  line-height: 0.75
  font-weight: 800
  font-size: 60vh
  opacity: .1
  color: $background-color
  white-space: nowrap
  text-shadow: -1px -1px 0 $fourth-color, 1px -1px 0 $fourth-color, -1px 1px 0 $fourth-color, 1px 1px 0 $fourth-color
  animation: text-scrolling 60s linear infinite
  &:nth-child(odd)
    animation-direction: reverse
