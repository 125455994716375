main
    overflow: hidden
    animation: fade-in 3s ease forwards

.content
    position: absolute
    transition: $scrolling-transition

.section
    height: calc(100vh - 70px - 35px)
    width: 100vw
    padding: 70px 0px 35px 0px
    display: flex
    align-items: center

.section-content-flex
    width: 100%
    height: 100%
    display: flex
    flex-wrap: wrap
    align-content: center
    justify-content: center

a
    text-decoration: none
    cursor: none
    color: $third-color

.cards-content
    position: relative
    display: flex
    justify-content: flex-start
    overflow: visible
    transition: left 500ms

.networks-content
    width: 100%
    height: 50%
    display: flex
    flex-wrap: wrap
    align-content: center
    justify-content: center
    margin: $margin

.keys
    width: 100vw
    display: flex
    justify-content: center
    svg
        margin: 25px 25px 0px 25px
        transition: transform 300ms, fill 300ms
        fill: $third-color
        @media screen and (min-width: $small)
            width: 40px
            height: 40px
        @media screen and (max-width: $small)
            width: 25px
            height: 25px
        display: flex
        justify-content: center
        &:active
            transform: scale(1.2)
            fill: $first-color

.link
    position: relative
    padding-bottom: 5px
    color: $first-color
    &:after
        background: none repeat scroll 0 0 transparent
        bottom: 0
        content: ""
        display: block
        height: 2px
        width: 0px
        left: 50%
        position: absolute
        background: $first-color
        transition: width 0.3s ease 0s, left 0.3s ease 0s
    &:hover:after
        width: 100%
        left: 0

h2
    font-weight: normal

h3
    font-weight: normal
