.network
    margin: $margin
    font-family: $third-font-family
    @media screen and (min-width: $small)
        width: 200px
        height: 50px
    @media screen and (max-width: $small)
        width: 200px
        height: 50px

.network-content
    display: grid
    &:hover svg
        transform: scale(1.1)
    @media screen and (min-width: $small)
        grid-template-rows: 25px 25px
        grid-auto-columns: 50px 150px
        grid-gap: 5px
    @media screen and (max-width: $small)
        grid-template-rows: 25px 25px
        grid-auto-columns: 50px 150px
    &:hover
        color: $first-color

.network-title
    grid-column: 2
    grid-row: 1
    display: flex
    align-items: center
    @media screen and (max-width: $small)
        justify-content: center
    h3
        transition: color 1s
        margin: 0px
        font-weight: bold
        @media screen and (min-width: $small)
            font-size: 18px
        @media screen and (max-width: $small)
            font-size: 12px

.network-logo
    grid-column: 1
    grid-row: 1 / span 2
    display: flex
    align-items: center
    justify-content: center
    svg
        height: 100%
        transition: transform 300ms
        @media screen and (min-width: $small)
            width: 50px
        @media screen and (max-width: $small)
            width: 25px     

.network-description
    grid-column: 2
    grid-row: 2
    display: flex
    align-items: center
    transition: color 1s
    @media screen and (min-width: $small)
        font-size: 16px
    @media screen and (max-width: $small)
        font-size: 12px
        justify-content: center

.random-svg
    fill: $third-color