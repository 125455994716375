$background-color: #2c2c2c //background
$first-color: #F7C873 //title
$second-color: #FAEBCD //seconds titles, pages and background card
$third-color: #ffffff //content, cursor
$fourth-color: #F8F8F8 //background's letters border
$fifth-color: #F7C873 //award category
$margin: 10px
$picture-border: solid 3px rgba($third-color, 0.25)
$card-background: rgba($second-color, 0.1)
$card-border-dark: solid 2px rgba($fifth-color, 1)
$card-border-light: solid 2px rgba($third-color, 1)
$card-border-radius: 3px
$first-font-family: 'Playfair Display italic'
$second-font-family: 'Source Sans Pro'
$third-font-family: 'Source Sans Pro'
$scrolling-transition: transform 1s ease

$extra-large: 1200px
$large: 992px
$medium: 768px
$small: 600px 

 