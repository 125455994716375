.award
  left: 0px

.card-award
  margin: 0px $margin
  background-color: $card-background
  border-radius: $card-border-radius
  padding: 5px
  display: grid
  grid-gap: 0px
  font-family: $third-font-family
  color: $third-color
  &:nth-child(2n)
    border-top: $card-border-dark
  &:nth-child(2n+1)
    border-top: $card-border-light
  @media screen and (min-width: $medium)
    height: 350px
    width: 250px
    grid-template-rows: 100px 70px 38px 142px
  @media screen and (max-width: $medium)
    height: 300px
    width: 214px
    grid-template-rows: 86px 60px 33px 121px

.card-award-date
  grid-row: 1
  color: $third-color
  opacity: .25
  font-weight: bold
  @media screen and (min-width: $medium)
    font-size: 100px
  @media screen and (max-width: $medium)
    font-size: 86px
 
.card-award-title
  grid-row: 2
  h3
    margin: $margin 0px
    @media screen and (min-width: $medium)
      font-size: 20px
    @media screen and (max-width: $medium)
      font-size: 16px

.card-award-category
  grid-row: 3
  clip-path: polygon(0% 0%, 100% 0, 90% 50%, 100% 100%, 0% 100%)
  background-color: $fifth-color
  color: $third-color
  line-height: 16px
  padding: 0px 0px 0px 10px
  display: flex
  align-items: center
  span
    width: 90%
  @media screen and (min-width: $medium)
    font-size: 16px
    transform: translateX(-10px)
  @media screen and (max-width: $medium)
    font-size: 14px
    transform: translateX(-7px)

.card-award-film
  grid-row: 4
  padding: $margin 0px
  font-style: italic
  @media screen and (min-width: $medium)
    font-size: 20px
  @media screen and (max-width: $medium)
    font-size: 16px

