footer
  position: fixed
  bottom: 0
  z-index: 1
  height: 35px
  min-width: 100%
  color: $second-color
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  nav
    display: flex
    justify-content: center

.items
  font-family: $second-font-family
  overflow: hidden
  position: relative
  height: 21px
  width: 21px
  font-size: 18px

.pagination
  width: 21px
  position: absolute
  display: grid
  grid-template-rows: repeat(5, 21px)
  transition: $scrolling-transition

.bullet
  background-color: $second-color
  border-radius: 50%
  width: 6px
  height: 6px
  margin: 10px 0px

.scroll-page
  animation: scroll-page 1s ease forwards